<template>
  <div>
    <h3>
      <span class="cor01">종목별선택</span>
    </h3>
    <div class="game_type_buttons">
      <button class="button_type01 text-all" :class="{'type_active':gameType===0}"  @click="gameTypeChange(0)">전체</button>
      <button class="button_type01" :class="{'type_active':gameType===49}" @click="gameTypeChange(49)"><img src="../../assets/images/left/icon/football-ico.png" alt="축구"></button>
      <button class="button_type01" :class="{'type_active':gameType===50}" @click="gameTypeChange(50)"><img src="../../assets/images/left/icon/baseball-ico.png" alt="야구"></button>
      <button class="button_type01" :class="{'type_active':gameType===51}" @click="gameTypeChange(51)"><img src="../../assets/images/left/icon/basketball-ico.png" alt="농구"></button>
      <button class="button_type01" :class="{'type_active':gameType===52}" @click="gameTypeChange(52)"><img src="../../assets/images/left/icon/volleyball-ico.png" alt="배구"></button>
      <button class="button_type01" :class="{'type_active':gameType===53}" @click="gameTypeChange(53)"><img src="../../assets/images/left/icon/hockey-ico.png" alt="하키"></button>
      <button class="button_type01" :class="{'type_active':gameType===56}" @click="gameTypeChange(56)"><img src="../../assets/images/left/icon/esport-ico.png" alt="E-SPORTS"></button>
      <button class="button_type01" :class="{'type_active':gameType===55}" @click="gameTypeChange(55)"><img src="../../assets/images/left/icon/tennis-ico.png" alt="테니스"></button>

    </div>

  </div>
</template>

<script>
  import sportsConst from "../../common/sportsConst";
  export default {
    name: "GameTypeSelectorComp",
    data(){
      return {
        gameType :0,
        sportsConst
      }
    },
    methods:{
      gameTypeChange(type){
        this.gameType = type
        // SportsNormalComp.vue 에서 $on
        this.$bus.$emit('gameTypeChange', type)
      }
    }
  }
</script>

<style scoped>
  .game_type_buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }

  .game_type_buttons button {
    width: 18%;
    margin: 4px 2px;
  }
  .game_type_buttons button img{
    width: 80%;
  }
  .game_type_buttons .text-all{
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
  }
  .game_type_buttons .type_active{
    color: #ffffff;
    font-weight: bold;
    background-color: #f19f09;
  }

</style>